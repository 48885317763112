import { default as _91id_9347vbFR8rCiMeta } from "/opt/atlassian/pipelines/agent/build/pages/accounts/[id].vue?macro=true";
import { default as indexfERx8Y98uFMeta } from "/opt/atlassian/pipelines/agent/build/pages/accounts/index.vue?macro=true";
import { default as _91id_93SqW6UdouokMeta } from "/opt/atlassian/pipelines/agent/build/pages/admins/[id].vue?macro=true";
import { default as createpoWLBG0l15Meta } from "/opt/atlassian/pipelines/agent/build/pages/admins/create.vue?macro=true";
import { default as indexCRitrQ89GiMeta } from "/opt/atlassian/pipelines/agent/build/pages/admins/index.vue?macro=true";
import { default as _91id_93G4ml9b5uipMeta } from "/opt/atlassian/pipelines/agent/build/pages/advisers/[id].vue?macro=true";
import { default as createzlhSVoD05nMeta } from "/opt/atlassian/pipelines/agent/build/pages/advisers/create.vue?macro=true";
import { default as indexjJW25G7NsbMeta } from "/opt/atlassian/pipelines/agent/build/pages/advisers/index.vue?macro=true";
import { default as asset_45classest5hpUqihG0Meta } from "/opt/atlassian/pipelines/agent/build/pages/asset-classes.vue?macro=true";
import { default as errorKztyOlJUZuMeta } from "/opt/atlassian/pipelines/agent/build/pages/auth/error.vue?macro=true";
import { default as login103yOntRZlMeta } from "/opt/atlassian/pipelines/agent/build/pages/auth/login.vue?macro=true";
import { default as pinKjzezNbdBgMeta } from "/opt/atlassian/pipelines/agent/build/pages/auth/pin.vue?macro=true";
import { default as authenticatorsBjZIYwx7ZRMeta } from "/opt/atlassian/pipelines/agent/build/pages/authenticators.vue?macro=true";
import { default as authorsISuiNz9VJIMeta } from "/opt/atlassian/pipelines/agent/build/pages/authors.vue?macro=true";
import { default as balance_45sheet_45categoriest6yYlVc152Meta } from "/opt/atlassian/pipelines/agent/build/pages/balance-sheet-categories.vue?macro=true";
import { default as _91id_93o9Dc4iSOUCMeta } from "/opt/atlassian/pipelines/agent/build/pages/balance-sheets/[id].vue?macro=true";
import { default as create5DnjpPpKalMeta } from "/opt/atlassian/pipelines/agent/build/pages/balance-sheets/create.vue?macro=true";
import { default as indexD9jIClvslFMeta } from "/opt/atlassian/pipelines/agent/build/pages/balance-sheets/index.vue?macro=true";
import { default as indexOD9t3F2bSJMeta } from "/opt/atlassian/pipelines/agent/build/pages/index.vue?macro=true";
import { default as media_45assetsrjKjIU9pgGMeta } from "/opt/atlassian/pipelines/agent/build/pages/media-assets.vue?macro=true";
import { default as _91id_93tp9Q1gjXB8Meta } from "/opt/atlassian/pipelines/agent/build/pages/modules/[id].vue?macro=true";
import { default as indexKmpiKMIPhVMeta } from "/opt/atlassian/pipelines/agent/build/pages/modules/index.vue?macro=true";
import { default as _91id_934WkkglZpBHMeta } from "/opt/atlassian/pipelines/agent/build/pages/multimedia/[id].vue?macro=true";
import { default as createj6aEN9oDBmMeta } from "/opt/atlassian/pipelines/agent/build/pages/multimedia/create.vue?macro=true";
import { default as indexnBRP97TuBWMeta } from "/opt/atlassian/pipelines/agent/build/pages/multimedia/index.vue?macro=true";
import { default as _91id_93cIX1dV1rGKMeta } from "/opt/atlassian/pipelines/agent/build/pages/prospects/[id].vue?macro=true";
import { default as createG8ZyhpFDJOMeta } from "/opt/atlassian/pipelines/agent/build/pages/prospects/create.vue?macro=true";
import { default as indexUzJmxxrQdlMeta } from "/opt/atlassian/pipelines/agent/build/pages/prospects/index.vue?macro=true";
import { default as reactions8GPlFlhmchMeta } from "/opt/atlassian/pipelines/agent/build/pages/reactions.vue?macro=true";
import { default as _91id_93qX6cIDBy9OMeta } from "/opt/atlassian/pipelines/agent/build/pages/segments/[id].vue?macro=true";
import { default as createR5qkIY1JKfMeta } from "/opt/atlassian/pipelines/agent/build/pages/segments/create.vue?macro=true";
import { default as index75Y0KjkFIvMeta } from "/opt/atlassian/pipelines/agent/build/pages/segments/index.vue?macro=true";
import { default as system_45messagesCk94neDgEiMeta } from "/opt/atlassian/pipelines/agent/build/pages/system-messages.vue?macro=true";
import { default as testerdDqUx4GvWqMeta } from "/opt/atlassian/pipelines/agent/build/pages/tester.vue?macro=true";
import { default as _91id_93iYd18ltivfMeta } from "/opt/atlassian/pipelines/agent/build/pages/users/[id].vue?macro=true";
import { default as indexY7hbUeKgEYMeta } from "/opt/atlassian/pipelines/agent/build/pages/users/index.vue?macro=true";
export default [
  {
    name: _91id_9347vbFR8rCiMeta?.name ?? "accounts-id",
    path: _91id_9347vbFR8rCiMeta?.path ?? "/accounts/:id()",
    meta: _91id_9347vbFR8rCiMeta || {},
    alias: _91id_9347vbFR8rCiMeta?.alias || [],
    redirect: _91id_9347vbFR8rCiMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/accounts/[id].vue").then(m => m.default || m)
  },
  {
    name: indexfERx8Y98uFMeta?.name ?? "accounts",
    path: indexfERx8Y98uFMeta?.path ?? "/accounts",
    meta: indexfERx8Y98uFMeta || {},
    alias: indexfERx8Y98uFMeta?.alias || [],
    redirect: indexfERx8Y98uFMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/accounts/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93SqW6UdouokMeta?.name ?? "admins-id",
    path: _91id_93SqW6UdouokMeta?.path ?? "/admins/:id()",
    meta: _91id_93SqW6UdouokMeta || {},
    alias: _91id_93SqW6UdouokMeta?.alias || [],
    redirect: _91id_93SqW6UdouokMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/admins/[id].vue").then(m => m.default || m)
  },
  {
    name: createpoWLBG0l15Meta?.name ?? "admins-create",
    path: createpoWLBG0l15Meta?.path ?? "/admins/create",
    meta: createpoWLBG0l15Meta || {},
    alias: createpoWLBG0l15Meta?.alias || [],
    redirect: createpoWLBG0l15Meta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/admins/create.vue").then(m => m.default || m)
  },
  {
    name: indexCRitrQ89GiMeta?.name ?? "admins",
    path: indexCRitrQ89GiMeta?.path ?? "/admins",
    meta: indexCRitrQ89GiMeta || {},
    alias: indexCRitrQ89GiMeta?.alias || [],
    redirect: indexCRitrQ89GiMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/admins/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93G4ml9b5uipMeta?.name ?? "advisers-id",
    path: _91id_93G4ml9b5uipMeta?.path ?? "/advisers/:id()",
    meta: _91id_93G4ml9b5uipMeta || {},
    alias: _91id_93G4ml9b5uipMeta?.alias || [],
    redirect: _91id_93G4ml9b5uipMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/advisers/[id].vue").then(m => m.default || m)
  },
  {
    name: createzlhSVoD05nMeta?.name ?? "advisers-create",
    path: createzlhSVoD05nMeta?.path ?? "/advisers/create",
    meta: createzlhSVoD05nMeta || {},
    alias: createzlhSVoD05nMeta?.alias || [],
    redirect: createzlhSVoD05nMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/advisers/create.vue").then(m => m.default || m)
  },
  {
    name: indexjJW25G7NsbMeta?.name ?? "advisers",
    path: indexjJW25G7NsbMeta?.path ?? "/advisers",
    meta: indexjJW25G7NsbMeta || {},
    alias: indexjJW25G7NsbMeta?.alias || [],
    redirect: indexjJW25G7NsbMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/advisers/index.vue").then(m => m.default || m)
  },
  {
    name: asset_45classest5hpUqihG0Meta?.name ?? "asset-classes",
    path: asset_45classest5hpUqihG0Meta?.path ?? "/asset-classes",
    meta: asset_45classest5hpUqihG0Meta || {},
    alias: asset_45classest5hpUqihG0Meta?.alias || [],
    redirect: asset_45classest5hpUqihG0Meta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/asset-classes.vue").then(m => m.default || m)
  },
  {
    name: errorKztyOlJUZuMeta?.name ?? "auth-error",
    path: errorKztyOlJUZuMeta?.path ?? "/auth/error",
    meta: errorKztyOlJUZuMeta || {},
    alias: errorKztyOlJUZuMeta?.alias || [],
    redirect: errorKztyOlJUZuMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/auth/error.vue").then(m => m.default || m)
  },
  {
    name: login103yOntRZlMeta?.name ?? "auth-login",
    path: login103yOntRZlMeta?.path ?? "/auth/login",
    meta: login103yOntRZlMeta || {},
    alias: login103yOntRZlMeta?.alias || [],
    redirect: login103yOntRZlMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: pinKjzezNbdBgMeta?.name ?? "auth-pin",
    path: pinKjzezNbdBgMeta?.path ?? "/auth/pin",
    meta: pinKjzezNbdBgMeta || {},
    alias: pinKjzezNbdBgMeta?.alias || [],
    redirect: pinKjzezNbdBgMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/auth/pin.vue").then(m => m.default || m)
  },
  {
    name: authenticatorsBjZIYwx7ZRMeta?.name ?? "authenticators",
    path: authenticatorsBjZIYwx7ZRMeta?.path ?? "/authenticators",
    meta: authenticatorsBjZIYwx7ZRMeta || {},
    alias: authenticatorsBjZIYwx7ZRMeta?.alias || [],
    redirect: authenticatorsBjZIYwx7ZRMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/authenticators.vue").then(m => m.default || m)
  },
  {
    name: authorsISuiNz9VJIMeta?.name ?? "authors",
    path: authorsISuiNz9VJIMeta?.path ?? "/authors",
    meta: authorsISuiNz9VJIMeta || {},
    alias: authorsISuiNz9VJIMeta?.alias || [],
    redirect: authorsISuiNz9VJIMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/authors.vue").then(m => m.default || m)
  },
  {
    name: balance_45sheet_45categoriest6yYlVc152Meta?.name ?? "balance-sheet-categories",
    path: balance_45sheet_45categoriest6yYlVc152Meta?.path ?? "/balance-sheet-categories",
    meta: balance_45sheet_45categoriest6yYlVc152Meta || {},
    alias: balance_45sheet_45categoriest6yYlVc152Meta?.alias || [],
    redirect: balance_45sheet_45categoriest6yYlVc152Meta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/balance-sheet-categories.vue").then(m => m.default || m)
  },
  {
    name: _91id_93o9Dc4iSOUCMeta?.name ?? "balance-sheets-id",
    path: _91id_93o9Dc4iSOUCMeta?.path ?? "/balance-sheets/:id()",
    meta: _91id_93o9Dc4iSOUCMeta || {},
    alias: _91id_93o9Dc4iSOUCMeta?.alias || [],
    redirect: _91id_93o9Dc4iSOUCMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/balance-sheets/[id].vue").then(m => m.default || m)
  },
  {
    name: create5DnjpPpKalMeta?.name ?? "balance-sheets-create",
    path: create5DnjpPpKalMeta?.path ?? "/balance-sheets/create",
    meta: create5DnjpPpKalMeta || {},
    alias: create5DnjpPpKalMeta?.alias || [],
    redirect: create5DnjpPpKalMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/balance-sheets/create.vue").then(m => m.default || m)
  },
  {
    name: indexD9jIClvslFMeta?.name ?? "balance-sheets",
    path: indexD9jIClvslFMeta?.path ?? "/balance-sheets",
    meta: indexD9jIClvslFMeta || {},
    alias: indexD9jIClvslFMeta?.alias || [],
    redirect: indexD9jIClvslFMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/balance-sheets/index.vue").then(m => m.default || m)
  },
  {
    name: indexOD9t3F2bSJMeta?.name ?? "index",
    path: indexOD9t3F2bSJMeta?.path ?? "/",
    meta: indexOD9t3F2bSJMeta || {},
    alias: indexOD9t3F2bSJMeta?.alias || [],
    redirect: indexOD9t3F2bSJMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/index.vue").then(m => m.default || m)
  },
  {
    name: media_45assetsrjKjIU9pgGMeta?.name ?? "media-assets",
    path: media_45assetsrjKjIU9pgGMeta?.path ?? "/media-assets",
    meta: media_45assetsrjKjIU9pgGMeta || {},
    alias: media_45assetsrjKjIU9pgGMeta?.alias || [],
    redirect: media_45assetsrjKjIU9pgGMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/media-assets.vue").then(m => m.default || m)
  },
  {
    name: _91id_93tp9Q1gjXB8Meta?.name ?? "modules-id",
    path: _91id_93tp9Q1gjXB8Meta?.path ?? "/modules/:id()",
    meta: _91id_93tp9Q1gjXB8Meta || {},
    alias: _91id_93tp9Q1gjXB8Meta?.alias || [],
    redirect: _91id_93tp9Q1gjXB8Meta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/modules/[id].vue").then(m => m.default || m)
  },
  {
    name: indexKmpiKMIPhVMeta?.name ?? "modules",
    path: indexKmpiKMIPhVMeta?.path ?? "/modules",
    meta: indexKmpiKMIPhVMeta || {},
    alias: indexKmpiKMIPhVMeta?.alias || [],
    redirect: indexKmpiKMIPhVMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/modules/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_934WkkglZpBHMeta?.name ?? "multimedia-id",
    path: _91id_934WkkglZpBHMeta?.path ?? "/multimedia/:id()",
    meta: _91id_934WkkglZpBHMeta || {},
    alias: _91id_934WkkglZpBHMeta?.alias || [],
    redirect: _91id_934WkkglZpBHMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/multimedia/[id].vue").then(m => m.default || m)
  },
  {
    name: createj6aEN9oDBmMeta?.name ?? "multimedia-create",
    path: createj6aEN9oDBmMeta?.path ?? "/multimedia/create",
    meta: createj6aEN9oDBmMeta || {},
    alias: createj6aEN9oDBmMeta?.alias || [],
    redirect: createj6aEN9oDBmMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/multimedia/create.vue").then(m => m.default || m)
  },
  {
    name: indexnBRP97TuBWMeta?.name ?? "multimedia",
    path: indexnBRP97TuBWMeta?.path ?? "/multimedia",
    meta: indexnBRP97TuBWMeta || {},
    alias: indexnBRP97TuBWMeta?.alias || [],
    redirect: indexnBRP97TuBWMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/multimedia/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93cIX1dV1rGKMeta?.name ?? "prospects-id",
    path: _91id_93cIX1dV1rGKMeta?.path ?? "/prospects/:id()",
    meta: _91id_93cIX1dV1rGKMeta || {},
    alias: _91id_93cIX1dV1rGKMeta?.alias || [],
    redirect: _91id_93cIX1dV1rGKMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/prospects/[id].vue").then(m => m.default || m)
  },
  {
    name: createG8ZyhpFDJOMeta?.name ?? "prospects-create",
    path: createG8ZyhpFDJOMeta?.path ?? "/prospects/create",
    meta: createG8ZyhpFDJOMeta || {},
    alias: createG8ZyhpFDJOMeta?.alias || [],
    redirect: createG8ZyhpFDJOMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/prospects/create.vue").then(m => m.default || m)
  },
  {
    name: indexUzJmxxrQdlMeta?.name ?? "prospects",
    path: indexUzJmxxrQdlMeta?.path ?? "/prospects",
    meta: indexUzJmxxrQdlMeta || {},
    alias: indexUzJmxxrQdlMeta?.alias || [],
    redirect: indexUzJmxxrQdlMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/prospects/index.vue").then(m => m.default || m)
  },
  {
    name: reactions8GPlFlhmchMeta?.name ?? "reactions",
    path: reactions8GPlFlhmchMeta?.path ?? "/reactions",
    meta: reactions8GPlFlhmchMeta || {},
    alias: reactions8GPlFlhmchMeta?.alias || [],
    redirect: reactions8GPlFlhmchMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/reactions.vue").then(m => m.default || m)
  },
  {
    name: _91id_93qX6cIDBy9OMeta?.name ?? "segments-id",
    path: _91id_93qX6cIDBy9OMeta?.path ?? "/segments/:id()",
    meta: _91id_93qX6cIDBy9OMeta || {},
    alias: _91id_93qX6cIDBy9OMeta?.alias || [],
    redirect: _91id_93qX6cIDBy9OMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/segments/[id].vue").then(m => m.default || m)
  },
  {
    name: createR5qkIY1JKfMeta?.name ?? "segments-create",
    path: createR5qkIY1JKfMeta?.path ?? "/segments/create",
    meta: createR5qkIY1JKfMeta || {},
    alias: createR5qkIY1JKfMeta?.alias || [],
    redirect: createR5qkIY1JKfMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/segments/create.vue").then(m => m.default || m)
  },
  {
    name: index75Y0KjkFIvMeta?.name ?? "segments",
    path: index75Y0KjkFIvMeta?.path ?? "/segments",
    meta: index75Y0KjkFIvMeta || {},
    alias: index75Y0KjkFIvMeta?.alias || [],
    redirect: index75Y0KjkFIvMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/segments/index.vue").then(m => m.default || m)
  },
  {
    name: system_45messagesCk94neDgEiMeta?.name ?? "system-messages",
    path: system_45messagesCk94neDgEiMeta?.path ?? "/system-messages",
    meta: system_45messagesCk94neDgEiMeta || {},
    alias: system_45messagesCk94neDgEiMeta?.alias || [],
    redirect: system_45messagesCk94neDgEiMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/system-messages.vue").then(m => m.default || m)
  },
  {
    name: testerdDqUx4GvWqMeta?.name ?? "tester",
    path: testerdDqUx4GvWqMeta?.path ?? "/tester",
    meta: testerdDqUx4GvWqMeta || {},
    alias: testerdDqUx4GvWqMeta?.alias || [],
    redirect: testerdDqUx4GvWqMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/tester.vue").then(m => m.default || m)
  },
  {
    name: _91id_93iYd18ltivfMeta?.name ?? "users-id",
    path: _91id_93iYd18ltivfMeta?.path ?? "/users/:id()",
    meta: _91id_93iYd18ltivfMeta || {},
    alias: _91id_93iYd18ltivfMeta?.alias || [],
    redirect: _91id_93iYd18ltivfMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/users/[id].vue").then(m => m.default || m)
  },
  {
    name: indexY7hbUeKgEYMeta?.name ?? "users",
    path: indexY7hbUeKgEYMeta?.path ?? "/users",
    meta: indexY7hbUeKgEYMeta || {},
    alias: indexY7hbUeKgEYMeta?.alias || [],
    redirect: indexY7hbUeKgEYMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/users/index.vue").then(m => m.default || m)
  }
]